.h1 {
  color: #333;
  text-align: center;
}
.allevent-container {
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  
  .allevent-item {
    background-color: #fff;
    box-shadow: 0 2px 5px rgba(0,0,0,0.1);
    margin-bottom: 20px;
    padding: 15px;
    width: 80%;
    border-radius: 8px;
  }
  
  .event-item h2 {
    color: #5D5C61;
    margin: 0 0 10px 0;
  }
  
  .event-item p {
    color: #379683;
    margin: 5px 0;
  }
  
  .button {
    background-color: #557A95;
    color: white;
    border: none;
    padding: 10px 20px;
    margin: 10px;
    border-radius: 5px;
    cursor: pointer;
    transition: background-color 0.3s ease;
  }
  
  .button:hover {
    background-color: #417B5A;
  }
  
  .volver-btn {
    background-color: #557A95;
    color: white;
    border: none;
    padding: 10px 20px;
    margin: 10px;
    border-radius: 5px;
    cursor: pointer;
    transition: background-color 0.3s ease;
    position: fixed;
    bottom: 20px;
    right: 20px;
  }
  
  button.eliminar-btn {
      background-color: #D9534F; /* Rojo */
      color: white;
      border: none;
      padding: 10px 20px;
      margin: 10px;
      border-radius: 5px;
      cursor: pointer;
      transition: background-color 0.3s ease;
    }
    
    button.eliminar-btn:hover {
      background-color: #C9302C; /* Rojo oscuro */
    }