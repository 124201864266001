.form-container {
    width: 80%;
    margin: 0 auto;
    padding: 20px;
    border: 1px solid #ccc;
    border-radius: 5px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
    background-color: #f9f9f9; /* Cambié el color de fondo */
}

.form-container label {
    display: block;
    margin-bottom: 10px;
}

.form-container input[type="text"],
.form-container input[type="date"],
.form-container select {
    width: calc(100% - 10px); /* Reduje el ancho para acomodar las fechas en la misma línea */
    padding: 8px;
    margin-bottom: 15px;
    border: 1px solid #ccc;
    border-radius: 4px;
    box-sizing: border-box;
}

.form-container button {
    background-color: #4caf50;
    color: white;
    padding: 10px 20px;
    border: none;
    border-radius: 4px;
    cursor: pointer;
    margin-top: 15px; /* Añadí un poco de espacio superior */
}

.form-container button:hover {
    background-color: #45a049;
}

.form-container .back-button {
    background-color: #f44336;
}

.form-container .back-button:hover {
    background-color: #d32f2f;
}

.error-message {
    color: #f44336;
    margin-top: 5px;
}
