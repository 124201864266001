/* menu */
.menu-container {
    position: absolute;
    height: 100%;
    max-width: 10%;
    min-width: 300px;
    background-color: #93ee4d;
    padding: 10px;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
}

.profile-button{
    background-color: transparent;
    border: transparent;
}

.profile-section {
    display: flex;
    align-items: center;
    margin-bottom: 20px;
}

.profile-image {
    width: 80px;
    height: 80px;
    border-radius: 50%;
    margin-right: 15px;
}

.profile-info {
    margin-right: 15px;
}

.menu-options {
    display: flex;
    flex-direction: column;
    margin-bottom: 20px;
}

.menu-option {
    background-color: transparent;
    border: none;
    margin: 20px 0;
    cursor: pointer;
    padding: 10px;
    font-size: 16px;
    text-align: left;
}

.menu-option:hover {
    background-color: #c3f1a5;
}

.logout-button {
    background-color: #666;
    color: #fff;
    border: none;
    padding: 10px 20px;
    border-radius: 5px;
    cursor: pointer;
}

/* header */

.menu-header {
    height: 100px;
    background-color: #ddd;
    color: black; 
    padding: 0 20px;
    box-sizing: border-box;
}

.header-content {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    height: 100%;
}

.menu-title {
    font-size: 20px;
    margin-left: 30%;
}

.menu-logo {
    width: 100px; /* Reducir el tamaño del logo */
    height: 70px;
    border-radius: 10%;
    margin-right: 10%;
}

/* event */
.event-list-container {
    /* background-color: #0056b3; */
    margin-left: 300px;
    padding-left: 30px;
    box-sizing: border-box; 
    height: 100%;
}

.event-list {
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(300px, 1fr));
    margin: 15px;
    gap: 20px;
}

.event-item {
    background-color: #f9f9f9;
    border-radius: 5px;
    margin-bottom: 5%;
    padding: 20px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
    width: 90%;
}

.event-item h2 {
    margin-top: 0;
    font-size: 20px;
    margin-bottom: 10px;
}

.event-item p {
    margin-bottom: 10px;
    font-size: 16px;
}

.event-item button {
    background-color: #007bff;
    color: #fff;
    border: none;
    padding: 8px 16px;
    border-radius: 5px;
    cursor: pointer;
    font-size: 16px;
    margin: 0px 10px;
}

.event-item button:hover {
    background-color: #0056b3;
}


.boton-volver{
    background-color: #7cb9ff;
    color: black;
    font-size: 20px;
    position: relative;
    margin: 10px;
    padding: 10px 20px;
    border-radius: 4px;
    transition: all 0.3s ease;
  }
  
  .boton-volver:hover {
    background-color: #6ba8e9;
  }