.event-info-container {
    padding: 20px;
    max-width: 600px;
    margin: 0 auto;
    text-align: center;
    background-color: #fff;
    border-radius: 10px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
    transition: all 0.3s ease;
}

.event-info-container:hover {
    box-shadow: 0 0 20px rgba(0, 0, 0, 0.2);
}

.event-info-container h1 {
    font-size: 24px;
    margin-bottom: 20px;
    color: #333;
}

.event-info-container h2 {
    font-size: 20px;
    margin-bottom: 10px;
    color: #555;
}

.event-info-container p {
    font-size: 16px;
    margin-bottom: 10px;
    color: #777;
}

.event-info-container button {
    padding: 10px 20px;
    font-size: 16px;
    border: none;
    cursor: pointer;
    transition: all 0.3s ease;
    border-radius: 4px;
    margin: 5px;
}

.event-info-container button[onClick="AddEvent()"] {
    background-color: #4caf50;
    color: white;
}

.event-info-container button[onClick="AddEvent()"]:hover {
    background-color: #45a049;
}

.event-info-container button[onClick="Volver"] {
    background-color: #7cb9ff;
    color: black;
}

.event-info-container button[onClick="Volver"]:hover {
    background-color: #6ba8e9;
}
