body {
  background-color: #f4f4f4;
  font-family: Arial, sans-serif;
}

.user-table {
  width: 100%;
  border-collapse: collapse;
  margin: 20px auto;
  background-color: #fff;
  box-shadow: 0px 0px 20px 0px rgba(15, 15, 15, 0.05);
}

.user-table th, .user-table td {
  border: 1px solid #dddddd;
  padding: 12px 15px;
}

.user-table th {
  background-color: #4caf50;
  color: white;
  text-align: left;
}

.user-table tr:nth-child(even) {
  background-color: #f2f2f2;
}

.user-table tr:hover {
  background-color: #ddd;
  transform: scale(1.02);
  transition: all 0.2s;
}

.user-table img {
  width: 50px;
  height: 50px;
  border-radius: 50%;
}

.user-table button {
  background-color: #f44336;
  color: white;
  border: none;
  padding: 10px 20px;
  text-align: center;
  text-decoration: none;
  display: inline-block;
  font-size: 14px;
  margin: 4px 2px;
  cursor: pointer;
  border-radius: 4px;
  transition: all 0.3s ease;
}

.user-table button:hover {
  opacity: 0.9;
}

.volver{
  background-color: #7cb9ff;
  color: black;
  font-size: 20px;
  position: relative;
  margin: 10px;
  padding: 10px 20px;
  border-radius: 4px;
  transition: all 0.3s ease;
}

.volver:hover {
  background-color: #6ba8e9;
}

.add{
  background-color: #20de46;
  color: black;
  font-size: 20px;
  position: relative;
  margin: 10px;
  padding: 10px 20px;
  border-radius: 4px;
  transition: all 0.3s ease;
}
.add:hover{
  background-color: #12a42f;
}

.filtro{
  float: right;
  margin-top: 10px;
  margin-right: 200px;
}
