/* Estilos para la página de actualización de usuario */
.updateuser-container {
    max-width: 600px;
    margin: 0 auto;
    padding: 20px;
    background-color: #f7f7f7;
    border-radius: 8px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  }
  
  .updateuser-title {
    margin-bottom: 20px;
    text-align: center;
    color: #333;
  }
  
  .updateuser-info {
    margin-bottom: 20px;
  }
  
  .updateuser-label {
    display: block;
    margin-bottom: 5px;
    color: #666;
  }
  
  .updateuser-select,
  .updateuser-input {
    width: 100%;
    padding: 10px;
    margin-bottom: 15px;
    border: 1px solid #ddd;
    border-radius: 4px;
    box-sizing: border-box;
  }
  
  .updateuser-button {
    display: block;
    width: 100%;
    padding: 10px;
    margin-bottom: 15px;
    background-color: #5cb85c;
    color: white;
    border: none;
    border-radius: 4px;
    cursor: pointer;
  }
  
  .updateuser-button:hover {
    background-color: #4cae4c;
  }
  
  /* Estilos específicos para el rol de Maestro */
  .updateuser-maestro-inputs {
    display: flex;
    flex-direction: column;
  }
  
  /* Estilos para el botón de volver */
  .updateuser-back-button {
    background-color: #f0ad4e;
  }
  
  .updateuser-back-button:hover {
    background-color: #ec971f;
  }
  